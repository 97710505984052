/* html, body, #root {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
} */


.nav-bar {
    position: relative;
    z-index: 9999; 
}

.bang {
    position: relative;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #BFDEE7;
    display: flex;
    justify-content: center;
    z-index: 1; 
}

.color-box {
    width: 30px;
    padding: 10px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-style: normal;
}


.ordinal {
    font-weight: bold;
    color: white; 
}

.list {
    display: flex;
    width: 600px;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center;
    padding: 0;
}

.list li {
    max-width: 200px; 
    display: flex;
    align-items: center;
}


.rando {
    margin-top: 0;
}

.draft {
    margin-bottom: 1px;
}

.players-collected-box {
    width: 700px;
    min-height: calc(100px + 40px * var(--num-players));
    flex-shrink: 0;
    margin: 100px;
    height: 400px;
    border: 10px solid #000;
    background: var(--Cream, #FFF2E9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
}

.confirm-button {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #F2A815;
    border: 3px solid #D32424; 
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px;
}


.confirm-button:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.confirm-button:focus {
    outline: none; 
}
