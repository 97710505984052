.nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  
  .nav-bar {
    margin: 0px;
    padding: 5px 0px 5px 20px;
    background-color: #BFDEE7;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
  }
  
  .nav-bottom-bar {
    font-size: 12px;
    bottom: 0px;
    margin: 0px;
    background-color: #FFF2E9;
    position: fixed;
    /* position: static; */
    width: 100%;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .nav-bottom-bar p {
    margin: 5px;
    /* font-weight: 1000; */
  }
  